import React, { useEffect, useState} from "react";
import sanityClient from "../client.js";
// import TransitionPixel from "./TransitionPixel";
import PreloaderPixel from './Preloader';
// import {ReactComponent as LogoWhite } from '../assets/logo-white.svg';

import "./Transition.scss";

const Transition = (props) => {

  const [transitionData, setTransitionData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "home"]{
            transitionText,
          }`
      )
      .then((data) => setTransitionData(data))
      .catch(console.error);
  }, []);

  return (
    <div className="transition__wrapper" id={'transitionWrapper'}>
      <div className="transition__grid-wrapper" id={'gridWrapper'}>
        {Array(300)
          .fill("")
          .map((item, index) => (
            <PreloaderPixel key={index} />
          ))}
      </div>
      <div className="transition__text-wrapper" id={'transText'}>
        {transitionData ? <h2>{transitionData[0].transitionText}</h2> : null}
      </div>
      <div className="transition__video-wrapper" id={'videoWrapper'}>
        <video
          src={''}
          id={'video'}
          muted={true}
          playsInline={true}
          loop={true}
          autoPlay={true}
          className="transition__video"
        ></video>
      </div>
    </div>
  );
}

export default Transition;
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

import {
  LeafMaterial,
  TrunkMaterial,
  LeafPointsMaterial,
  TrunkPointsMaterial,
} from "./Materials";

let leafSlug = "";

export function LeafModel({ ...props }) {
  leafSlug = props.leafSlug;
  const group = useRef();
  const { nodes } = useGLTF(`${leafSlug}`);
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    group.current.rotation.x = Math.PI / 2;
    group.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20;
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <group scale={[1.3, 1.3, 1.3]}>
        <mesh geometry={nodes.Leaves.geometry} material={LeafMaterial} />
      </group>
    </group>
  );
}

export function LeafPoints({ ...props }) {
  leafSlug = props.leafSlug;
  const group = useRef();
  const { nodes } = useGLTF(`${leafSlug}`);
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    group.current.rotation.x = Math.PI / 2;
    group.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20;
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <group scale={[1.3, 1.3, 1.3]}>
        <points
          geometry={nodes.Leaves.geometry}
          material={LeafPointsMaterial}
        />
      </group>
    </group>
  );
}

let trunkSlug = "";

export function TrunkModel({ ...props }) {
  trunkSlug = props.trunkSlug;
  const group = useRef();
  const { nodes } = useGLTF(`${trunkSlug}`);
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    group.current.rotation.x = Math.PI / 2;
    group.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20;
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <group scale={[1.3, 1.3, 1.3]}>
        <mesh geometry={nodes.Trunk.geometry} material={TrunkMaterial} />
      </group>
    </group>
  );
}

export function TrunkPoints({ ...props }) {
  trunkSlug = props.trunkSlug;
  const group = useRef();
  const { nodes } = useGLTF(`${trunkSlug}`);
  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    group.current.rotation.x = Math.PI / 2;
    group.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20;
  });
  return (
    <group ref={group} {...props} dispose={null}>
      <group scale={[1.3, 1.3, 1.3]}>
        <points
          geometry={nodes.Trunk.geometry}
          material={TrunkPointsMaterial}
        />
      </group>
    </group>
  );
}

useGLTF.preload(`${trunkSlug}`);
useGLTF.preload(`${leafSlug}`);

import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Preloader from "./Preloader";
import Transition from "./Transition";
import Header from "./Header";
import Footer from "./Footer.js";

import Home from "../views/home";
import Tree from "../views/tree";

const Content = (props) => {

  return (
    <BrowserRouter>
      <Preloader key={'preloader'} {...props} />
      <Transition key={'transition'} {...props}/>
      <Header key={'header'} {...props} />
      <Routes>
        <Route element={<Home key={"home"} {...props} />} path="/" />
        <Route element={<Tree key={"trees"} {...props} />} path="/:slug" />
      </Routes>
      <Footer {...props} />
    </BrowserRouter>
  );
}

export default Content;

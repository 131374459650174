import { useState } from "react";
import Content from "./components/Content";

function App() {

  const [transitionState, setTransitionState] = useState(0);
  const [videoSrc, setVideoSrc] = useState('')

  return (
    <Content
      transitionState={transitionState}
      setTransitionState={setTransitionState}
      videoSrc={videoSrc}
      setVideoSrc={setVideoSrc}
    />
  );
}

export default App;

import React, { useState, useEffect, Fragment } from "react";
import sanityClient from "../client.js";

import TextBlock from "../components/TextBlock.js";
import HorizontalScroll from "../components/HorizontalScroll.js";
import TitleParagraphBlock from "../components/TitleParagraphBlock.js";
import VideoBlock from "../components/VideoBlock.js";
import VideoSplit from "../components/VideoSplit.js";

const Home = () => {
  const [homePageData, setHomePageData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "home"]{
            slug,
            forestText[0]{children[0]{text}},
        furtherReadingText[0]{children[0]{text}},
        "homeVideoURL": homeVideo.asset->url,
        imagineText,
        "imagineVideo1URL": imagineVideo1.asset->url,
        "imagineVideo2URL": imagineVideo2.asset->url,
        "imagineVideo3URL": imagineVideo3.asset->url,
        howeverText,
        footerText,
        footerArray,
        }`
      )
      .then((data) => setHomePageData(data))
      .catch(console.error);
  }, []);

  return (
    <main>
      {homePageData &&
        homePageData.map((item, index) => (
          <Fragment key={item._key}>
            <TextBlock
              title={"Future Forest"}
              text={item.forestText.children.text}
              padding={"padding-both"}
              explore={true}
              delay={4000}
            />
            <HorizontalScroll />
            <TextBlock
              title={"Further Reading"}
              text={item.furtherReadingText.children.text}
              padding={"padding-both"}
              id={"furtherReading"}
            />
            <VideoBlock url={item.homeVideoURL} />
            <TitleParagraphBlock
              title={"Imagine"}
              subtitle={"we are in 2030"}
              text={item.imagineText}
              margin={true}
            />
            <VideoSplit
              url1={item.imagineVideo1URL}
              url2={item.imagineVideo2URL}
              url3={item.imagineVideo3URL}
            />
            <TitleParagraphBlock
              title={"However"}
              subtitle={"we're not there yet"}
              text={item.howeverText}
              margin={false}
            />
          </Fragment>
        ))}
    </main>
  );
};

export default Home;

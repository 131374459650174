import React from "react";
import Fade from "react-reveal/Fade";

import "./VideoSplit.scss";

const VideoSplit = (props) => {
  return (
    <section className="video-split__container">
      <div className="video-split__half left">
        <Fade bottom distance={"100px"}>
          <div className="video-split__wrapper video-one">
            {" "}
            <video
              src={`${props.url1}`}
              muted={true}
              playsInline={true}
              loop={true}
              autoPlay={true}
              className="video-split__video"
            ></video>{" "}
          </div>
        </Fade>
        <Fade bottom distance={"100px"} delay={200}>
          <div className="video-split__wrapper video-two">
            {" "}
            <video
              src={`${props.url2}`}
              muted={true}
              playsInline={true}
              loop={true}
              autoPlay={true}
              className="video-split__video"
            ></video>
          </div>
        </Fade>
      </div>
      <div className="video-split__half right">
        <Fade bottom distance={"100px"} delay={200}>
          <div className="video-split__wrapper video-three">
            {" "}
            <video
              src={`${props.url3}`}
              muted={true}
              playsInline={true}
              loop={true}
              autoPlay={true}
              className="video-split__video"
            ></video>
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default VideoSplit;

import * as THREE from "three";
import React, { useEffect, useRef } from "react";
import { Canvas, useThree, useFrame } from "@react-three/fiber";

import TWEEN from "@tweenjs/tween.js";

import {
  LeafModel,
  LeafPoints,
  TrunkModel,
  TrunkPoints,
} from "./models/LoadModels";
import {
  initCarbonState,
  initWaterState,
  initBioState,
  initIntro,
} from "./models/States";

import "./SceneComponent.scss";

let cameraPath = {
  camPosIndex: 0,
  pointsPath: new THREE.CurvePath(),
  pointOne: new THREE.CubicBezierCurve3(
    new THREE.Vector3(18, 17.5, -40),
    new THREE.Vector3(60, 40, 11),
    new THREE.Vector3(0, 70, 0),
    new THREE.Vector3(0, -5, 0)
  ),
  pointTwo: new THREE.CubicBezierCurve3(
    new THREE.Vector3(0, -5, 0),
    new THREE.Vector3(-40, 10, 10),
    new THREE.Vector3(0, 70, 0),
    new THREE.Vector3(18, 17.5, -45)
  ),
  pointThree: new THREE.CubicBezierCurve3(
    new THREE.Vector3(18, 17.5, -45),
    new THREE.Vector3(40, 20, 30),
    new THREE.Vector3(50, 20, 0),
    new THREE.Vector3(0, 20, 60)
  ),
};
cameraPath.pointsPath.add(cameraPath.pointOne);
cameraPath.pointsPath.add(cameraPath.pointTwo);
cameraPath.pointsPath.add(cameraPath.pointThree);

let lookAtPath = {
  camPosIndex: 0,
  pointsPath: new THREE.CurvePath(),
  pointOne: new THREE.CubicBezierCurve3(
    new THREE.Vector3(18, 17.5, 0),
    new THREE.Vector3(18, 40, 0),
    new THREE.Vector3(0, 40, 0),
    new THREE.Vector3(0, -10, 0)
  ),
  pointTwo: new THREE.CubicBezierCurve3(
    new THREE.Vector3(0, -10, 0),
    new THREE.Vector3(0, 10, 0),
    new THREE.Vector3(0, 70, 0),
    new THREE.Vector3(18, 17.5, 0)
  ),
  pointThree: new THREE.CubicBezierCurve3(
    new THREE.Vector3(18, 17.5, 0),
    new THREE.Vector3(30, 20, 30),
    new THREE.Vector3(0, 20, 0),
    new THREE.Vector3(0, 20, 40)
  ),
};
lookAtPath.pointsPath.add(lookAtPath.pointOne);
lookAtPath.pointsPath.add(lookAtPath.pointTwo);
lookAtPath.pointsPath.add(lookAtPath.pointThree);

const SceneComponent = (props) => {
  const { treeState, setTreeState } = props;

  const container = useRef();

  useEffect(()=>{
    document.body.style.overflow = "hidden";
  }, []);

  function Camera() {
    const camera = useThree((state) => state.camera);

    initIntro(camera, setTreeState);

    const handleScroll = () => {
      let totalHeight = container.current.offsetHeight - window.innerHeight;
      let wrapperCoords = container.current.getBoundingClientRect();
      let wrapperTop = -wrapperCoords.top;

      //MOVE CAMERA
      if (wrapperTop < totalHeight) {
        cameraPath.camPosIndex = wrapperTop;
      }
      var camPos = cameraPath.pointsPath.getPoint(
        cameraPath.camPosIndex / totalHeight
      );
      camera.position.x = camPos.x;
      camera.position.y = camPos.y;
      camera.position.z = camPos.z;

      var camRot = cameraPath.pointsPath.getTangent(
        cameraPath.camPosIndex / totalHeight
      );
      camera.rotation.x = camRot.x;
      camera.rotation.y = camRot.y;
      camera.rotation.z = camRot.z;

      camera.lookAt(
        lookAtPath.pointsPath.getPoint(cameraPath.camPosIndex / totalHeight)
      );

      //CHNGE STATE
      if (wrapperTop >= 0 && wrapperTop <= totalHeight / 3) {
        setTreeState("carbon");
        initCarbonState();
      } else if (
        wrapperTop >= totalHeight / 3 + 1 &&
        wrapperTop <= (totalHeight / 3) * 2
      ) {
        setTreeState("water");
        initWaterState();
      } else if (
        wrapperTop >= (totalHeight / 3) * 2 + 1 &&
        wrapperTop <= totalHeight
      ) {
        setTreeState("bio");
        initBioState();
      } else if (wrapperTop >= totalHeight + 1) {
        setTreeState("done");
      }
    };

    useEffect(() => {
      let wheelEvent =
        "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
        document.body.addEventListener(wheelEvent, handleScroll);
        document.body.addEventListener('scroll', handleScroll);
      return () => {
        document.body.removeEventListener(wheelEvent, handleScroll);
        document.body.removeEventListener('scroll', handleScroll);
      };
    });

    useFrame((state) => {
      TWEEN.update();
    });
  }

  return (
    <div
      className="scene__sticky-container"
      id="stickyContainer"
      ref={container}
    >
      <div className={`scene__sticky-wrapper ${treeState}`} id="stickyWrapper">
        <Canvas>
          <Camera></Camera>
          <spotLight
            position={[10, 10, 10]}
            angle={0.15}
            penumbra={1}
            shadow-mapSize={[512, 512]}
            castShadow
          />
          <LeafPoints leafSlug={props.leafModel}></LeafPoints>
          <TrunkPoints trunkSlug={props.trunkModel}></TrunkPoints>
          <LeafModel leafSlug={props.leafModel}></LeafModel>
          <TrunkModel trunkSlug={props.trunkModel}></TrunkModel>
        </Canvas>
      </div>
    </div>
  );
};

export default SceneComponent;

import React, { useEffect, useState, useRef } from "react";
import sanityClient from "../client.js";
import DelayLink from "./DelayLink.js";
import Fade from "react-reveal/Fade";

import startTransition from "./startTransition";

import "../styles/global-styles.scss";
import "./HorizontalScroll.scss";
import HorizontalScrollVideo from "./HorizontalScrollVideo";

// import { videoSrc } from "./Transition.js";

const HorizontalScroll = (props) => {

  const { setVideoSrc } = props;
  const [treeData, setTreeData] = useState(null);

  const horizontalContainer = useRef();
  const horizontalOuter = useRef();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "trees"]{
              title,
              slug,
              latinName,
              age,
              height,
              location,
              "previewURL": previewVideo.asset->url
          }`
      )
      .then((data) => setTreeData(data))
      .catch(console.error);
  }, []);

  const handleScroll = () => {
    let wrapperCoords = horizontalContainer.current.getBoundingClientRect();
    let wrapperTop = wrapperCoords.top;
    horizontalOuter.current.scrollTo(-wrapperTop, 0);
  };

  useEffect(() => {
    let wheelEvent =
      "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

    window.addEventListener(wheelEvent, handleScroll);

    return () => {
      window.removeEventListener(wheelEvent, handleScroll);
    };
  }, []);



  return (
      <section
        className="horizontal-scroll__container"
        ref={horizontalContainer}
      >
        <div className="horizontal-scroll__outer-wrapper" ref={horizontalOuter}>
        <Fade right delay={4000} duration={2000}>
          <div className="horizontal-scroll__inner-wrapper">
            {treeData &&
              treeData.map((tree, index) => (
                <DelayLink
                  delay={1000}
                  onDelayStart={startTransition}
                  // onDelayStart={(e) => {
                  //   // startTransition(e, setVideoSrc)

                  // }}
                  to={"/" + tree.slug.current}
                  key={index}
                  className="horizontal-scroll__item-wrapper"
                >
                  <div className="horizontal-scroll__item-details">
                    <h4>Age: {tree.age} years</h4>
                    <h4>Height: {tree.age} meters</h4>
                    <h4>Location: {tree.location} </h4>
                  </div>
                  <div className="horizontal-scroll__item-title">
                    <h3>{tree.title}</h3>
                    <h3 className="alt-font">{tree.latinName}</h3>
                  </div>
                  <HorizontalScrollVideo src={tree.previewURL} />
                </DelayLink>
              ))}
          </div>
          </Fade>
        </div>
      </section>
  );
}

export default HorizontalScroll;
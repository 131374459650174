import React from "react";
import {ReactComponent as LogoBlack } from '../assets/logo-black.svg';
import "./Header.scss";

import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header>
      <Link to={"/"} className="header__logo-wrapper">
          <LogoBlack />
      </Link>
      <div className="background-grid"></div>
    </header>
  );
}

import * as THREE from "three";
import TWEEN from "@tweenjs/tween.js";
import { LeafMaterial, TrunkMaterial, LeafPointsMaterial, TrunkPointsMaterial } from "./Materials"; 


export const Intro = {
  trunk: {
    r: 255,
    g: 255,
    b: 255,
    o: 0.5,
  },
  leaves: {
    r: 255,
    g: 255,
    b: 255,
    o: 0.5,
  },
  trunkPoints: {
    r: 255,
    g: 255,
    b: 255,
    o: 0.5,
  },
  leafPoints: {
    r: 255,
    g: 255,
    b: 255,
    o: 0.5,
  },
};

const Carbon = {
  trunk: {
    r: 47,
    g: 47,
    b: 80,
    o: 0.2,
  },
  leaves: {
    r: 93,
    g: 200,
    b: 136,
    o: 0.1,
  },
  trunkPoints: {
    r: 107,
    g: 240,
    b: 160,
    o: 0.6,
  },
  leafPoints: {
    r: 217,
    g: 210,
    b: 101,
    o: 0.6,
  },
};

const Water = {
  trunk: {
    r: 100,
    g: 100,
    b: 100,
    o: 0.3,
  },
  leaves: {
    r: 56,
    g: 74,
    b: 179,
    o: 0.1,
  },
  trunkPoints: {
    r: 71,
    g: 115,
    b: 193,
    o: 0.6,
  },
  leafPoints: {
    r: 56,
    g: 74,
    b: 179,
    o: 0.4,
  },
};

const Bio = {
  trunk: {
    r: 142,
    g: 135,
    b: 126,
    o: 0.7,
  },
  leaves: {
    r: 176,
    g: 225,
    b: 175,
    o: 0.2,
  },
  trunkPoints: {
    r: 128,
    g: 128,
    b: 220,
    o: 1,
  },
  leafPoints: {
    r: 147,
    g: 187,
    b: 254,
    o: 0.7,
  },
};

export let currentState = "intro";

const tweenColor = (from, to, material) => {
  let start = { ...from };

  new TWEEN.Tween(start)
    .to({ r: to.r, g: to.g, b: to.b, o: to.o }, 1000)
    .easing(TWEEN.Easing.Cubic.Out)
    .onUpdate(() => {
      material.color = new THREE.Color(
        `rgb(
            ${Math.trunc(start.r)},
            ${Math.trunc(start.g)}, 
            ${Math.trunc(start.b)}
        )`
      );
      material.opacity = start.o;
    })
    .onComplete(() => {})
    .start();
};

const changeAllColors = (from, to) => {
  tweenColor(from.leaves, to.leaves, LeafMaterial);
  tweenColor(from.trunk, to.trunk, TrunkMaterial);
  tweenColor(from.leafPoints, to.leafPoints, LeafPointsMaterial);
  tweenColor(from.trunkPoints, to.trunkPoints, TrunkPointsMaterial);
};

export let introDone = false
export const initIntro = (camera, setTreeState) => {

  if(!introDone){
  setTreeState('intro');

  camera.position.set(-40, 17.5, -60);
  camera.lookAt(-40, 17.5, 0);
  
  let camFrom = { x: -40, y: 17.5, z: -60 };
  let camTo = { x: 18, y: 17.5, z: -40 };
  let lookFrom = { x: -40, y: 17.5, z: 0 };
  let lookTo = { x: 18, y: 17.5, z: 0 };

  new TWEEN.Tween(camFrom)
    .to({ x: camTo.x, y: camTo.y, z: camTo.z }, 5000)
    .easing(TWEEN.Easing.Cubic.InOut)
    .onUpdate(() => {
      camera.position.set(camFrom.x, camFrom.y, camFrom.z);
    })
    .onComplete(() => {
      introDone = true;
      initCarbonState();
      setTreeState('carbon');
      document.body.style.overflow = null;
    })
    .start();

  new TWEEN.Tween(lookFrom)
    .to({ x: lookTo.x, y: lookTo.y, z: lookTo.z }, 4000)
    .easing(TWEEN.Easing.Cubic.InOut)
    .onUpdate(() => {
      camera.lookAt(lookFrom.x, lookFrom.y, lookFrom.z);
    })
    .onComplete(() => {})
    .start();
  }
}

export const initCarbonState = (wrapper, info) => {
  if (currentState !== "carbon") {
    if (currentState === "intro") {
      changeAllColors(Intro, Carbon);
    } else {
      changeAllColors(Water, Carbon);
    }
    currentState = "carbon";
  }
};

export const initWaterState = (wrapper) => {
  if (currentState !== "water") {
    // console.log("water");
    if (currentState === "carbon") {
      changeAllColors(Carbon, Water);
    } else {
      changeAllColors(Bio, Water);
    }
    currentState = "water";
  }
};

export const initBioState = (wrapper, info) => {
  if (currentState !== "bio") {
    changeAllColors(Water, Bio);
    currentState = "bio";
  }
};

// export const initEndState = (wrapper) => {
//   if(currentState !== "done"){
//     currentState = "done";
//     wrapper.classList.remove('active');
//   }
// }
import TransitionVideo from '../assets/transition.mp4';

const startTransition = (e) => {

  let transitionElms = {
    wrapper: document.getElementById("transitionWrapper"),
    videoWrapper: document.querySelector(".transition__video-wrapper"),
    video: document.querySelector(".transition__video"),
    gridWrapper: document.getElementById("gridWrapper"),
    pixels: document.querySelectorAll(".transition__pixel"),
    textWrapper: document.getElementById("transText"),
  };

  let videoSrc = e.target.src;

  if(videoSrc !== undefined){
    transitionElms.videoWrapper.style.top = `${
      e.target.parentElement.getBoundingClientRect().top
    }px`;
    transitionElms.videoWrapper.style.left = `${
      e.target.parentElement.getBoundingClientRect().left
    }px`;
  
    transitionElms.video.src = videoSrc;
    transitionElms.wrapper.classList.add("active");
  } else {
    videoSrc = TransitionVideo;
    transitionElms.video.src = videoSrc;
    transitionElms.videoWrapper.classList.add('transition');
    transitionElms.wrapper.classList.add("active");
  }


  setTimeout(() => {
    transitionElms.videoWrapper.classList.add("active");
      setTimeout(() => {
        transitionElms.textWrapper.classList.add("active");
        transitionElms.gridWrapper.classList.add("active");
        setTimeout(() => {
          transitionElms.videoWrapper.classList.add("fade-out");
          setTimeout(() => {
            endTransition();
          }, 1000);
        }, 2000);
      }, 1500);
  }, 200);

};

const endTransition = () => {

  let transitionElms = {
    wrapper: document.getElementById("transitionWrapper"),
    videoWrapper: document.querySelector(".transition__video-wrapper"),
    video: document.querySelector(".transition__video"),
    gridWrapper: document.getElementById("gridWrapper"),
    pixels: document.querySelectorAll(".transition__pixel"),
    textWrapper: document.getElementById("transText"),
  };

  transitionElms.gridWrapper.classList.remove("active");
  transitionElms.textWrapper.classList.remove("active");
  setTimeout(() => {
    transitionElms.wrapper.classList.remove("active");
  }, 1500);

  setTimeout(() => {
    transitionElms.videoWrapper.style.top = null;
    transitionElms.videoWrapper.style.left = null;

    transitionElms.video.src = null;
    transitionElms.videoWrapper.classList.remove("active");
    transitionElms.videoWrapper.classList.remove("fade-out");
    transitionElms.videoWrapper.classList.remove('transition');

  }, 3000);
};

export default startTransition;
import React from "react";
import Fade from "react-reveal/Fade";

import "./TextBlock.scss";

export default function TextBlock(props) {

  return (
    <section className={`text-block__wrapper ${props.padding}`} id={props.id}>
      <Fade bottom delay={0 + props.delay}>
        <h1 className="text-block__title">{props.title}</h1>
      </Fade>
      <Fade bottom delay={200 + props.delay}>
        <h2 className="text-block__text ">{props.text}</h2>
      </Fade>

      {props.explore ? (
        <Fade bottom delay={600 + props.delay}>
          <div className="text-block__explore">
            <h4>
              SELECT A TREE
              <br />
              TO EXPLORE
            </h4>
          </div>
        </Fade>
      ) : null}
    </section>
  );
}

import React, { useEffect, useState, useRef } from "react";
import sanityClient from '../client.js';
import {ReactComponent as LogoWhite } from '../assets/logo-white.svg';
import Fade from "react-reveal/Fade";
import "./Preloader.scss";


export const PreloaderPixel = () => {

  const [color] = useState(`#01${Math.round(Math.random() * (25 - 10) + 10)}00`);

  return (
    <div
      className="preloader__pixel"
      style={{backgroundColor: color}}
    />
  )
  
}

export default function Preloader() {

  const [preloaderData, setPreloaderData] = useState(null);
  const [animationState, setAnimationState] = useState(0);

  const preWrapper = useRef();
  const preLogo = useRef();
  const preGrid = useRef();
  const preTextWrapper = useRef();
  const preText = useRef();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "home"]{
            preloaderText,
            "preloaderVideoURL": preloaderVideo.asset->url,
          }`
      )
      .then((data) => setPreloaderData(data))
      .catch(console.error);
  }, []);
  
  useEffect(() => {
    let timeout = setTimeout(() => {
      setAnimationState(1);
      timeout = setTimeout(() => {
        setAnimationState(2);
        timeout = setTimeout(() => {
          setAnimationState(3);
        }, 2000);
      }, 1000);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    }
  }, []);


  if(preloaderData){
  return (
    <section className={`preloader__wrapper ${animationState >= 3 ? 'disabled' : ''}`} ref={preWrapper}>
      <div className={`preloader__logo-wrapper ${animationState >= 2 ? 'move-to-top' : ''}`} ref={preLogo}>
        <LogoWhite />
      </div>
      <div className={`preloader__grid-wrapper ${animationState >= 1 ? 'disabled' : ''}`} ref={preGrid}>
        {
          Array(300).fill('').map(
            (item, index) => (
              <PreloaderPixel key={index} />
            )
          )
        }
      </div>
      <div className={`preloader__text-wrapper ${animationState >= 3 ? 'disabled' : ''}`} ref={preTextWrapper}>
        <Fade bottom distance={'100px'} delay={2000}>
        <h2 className={`preloader__text ${animationState >= 2 ? 'active' : ''}`} ref={preText}>
          {preloaderData[0].preloaderText}
        </h2>
        </Fade>
      </div>
      <div className="preloader__video-wapper">
        <video
          src={`${preloaderData[0].preloaderVideoURL}`}
          muted={true}
          playsInline={true}
          loop={true}
          autoPlay={true}
          className="preloader__video"
        ></video>
      </div>
    </section>
  );
}}

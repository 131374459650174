import { useRef, useEffect } from "react";

const HorizontalScrollVideo = (props) => {

  // const video = useRef();

  // useEffect(() => {

  //   const handleMobileScroll = () => {
  //     if (window.innerWidth <= 750) {
  //       let top = video.current.getBoundingClientRect().top;
  //       let bottom = top + video.current.offsetHeight;
  //       if (
  //         top <= window.innerHeight / 2 &&
  //         bottom >= window.innerHeight / 2
  //       ) {
  //         video.current.play();
  //       } else {
  //         video.current.pause();
  //       }
  //     }
  //   }
    
  //   let wheelEvent =
  //     "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";
  //   window.addEventListener(wheelEvent, handleMobileScroll);
    
  //   return () => {
  //     window.removeEventListener(wheelEvent, handleMobileScroll);
  //   };
  // }, []);

  return (
    <video
      src={`${props.src}`}
      muted={true}
      playsInline={true}
      loop={true}
      className="horizontal-scroll__video"
      autoPlay={(window.innerWidth <= 750 ? true : false )}
      // ref={video}
      onMouseOver={(event) => event.target.play()}
      onMouseOut={(event) => event.target.pause()}
    ></video>
  )

};

export default HorizontalScrollVideo;
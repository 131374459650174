import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";

import VideoBlock from "../components/VideoBlock.js";
import TextBlock from "../components/TextBlock.js";
import SceneComponent from "../components/3d/SceneComponent.js";

import startTransition from "../components/startTransition";

import "./tree.scss";
import { HashLink } from "react-router-hash-link";
// import DelayLink from "../components/DelayLink.js";
import TreeSection from "../components/TreeSection.js";

export default function Tree() {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();
  const [treeState, setTreeState] = useState('');
  

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == $slug]{
          title,
          slug,
          "fullURL": fullVideo.asset->url,
          "trunkModelURL": trunkModel.asset->url,
          "leafModelURL": leafModel.asset->url,
          carbonText,
          waterText,
          bioText
       }`,
        { slug }
      )
      .then((data) => setPostData(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!postData) return <div>Loading...</div>;

  return (
    <main>
      <TreeSection
        textOne={postData.carbonText}
        textTwo={postData.waterText}
        textThree={postData.bioText}
        treeState={treeState}
        setTreeState={setTreeState}
      />

      <SceneComponent
        leafModel={postData.leafModelURL}
        trunkModel={postData.trunkModelURL}
        // scrollPosition={scrollPosition}
        // setScrollPosition={setScrollPosition}
        treeState={treeState}
        setTreeState={setTreeState}
      />
      <VideoBlock url={postData.fullURL} />
      <TextBlock
        title={"The Vision"}
        text={
          "With the help of emerging technologies, we can reimagine the future of responsible forestry."
        }
        padding={"padding-top"}
      />
      <div className="tree__cta-wrapper">
        {/* <DelayLink to={"/#furtherReading"} delay={1000} onDelayStart={startTransition}> */}
        <HashLink to="/#furtherReading" onClick={startTransition}>
          <h3 className="tree__cta">Find out more</h3>
        </HashLink>
        {/* </DelayLink> */}
      </div>
    </main>
  );
}

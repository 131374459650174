import * as THREE from "three";
import { Intro } from "./States";
import disc from './disc.png'; 

export const LeafMaterial = new THREE.MeshBasicMaterial({
  transparent: true,
  color: `rgb(${Intro.trunk.r}, ${Intro.trunk.g}, ${Intro.trunk.b})`,
  opacity: Intro.o,
  depthWrite: false,
});

export const TrunkMaterial = new THREE.MeshBasicMaterial({
  transparent: true,
  color: `rgb(${Intro.leaves.r}, ${Intro.leaves.g}, ${Intro.leaves.b})`,
  opacity: Intro.o,
  depthWrite: false,
});

const sprite = new THREE.TextureLoader().load(disc);

export const LeafPointsMaterial = new THREE.PointsMaterial({
  size: 0.2,
  color: `rgb(${Intro.leafPoints.r}, ${Intro.leafPoints.g}, ${Intro.leafPoints.b})`,
  depthWrite: false,
  sizeAttenuation: true,
  opacity: Intro.leafPoints.o,
  map: sprite,
  alphaTest: 0.5,
  transparent: true,
})

export const TrunkPointsMaterial = new THREE.PointsMaterial({
  size: 0.2,
  color: `rgb(${Intro.trunkPoints.r}, ${Intro.trunkPoints.g}, ${Intro.trunkPoints.b})`,
  depthWrite: false,
  sizeAttenuation: true,
  opacity: Intro.trunkPoints.o,
  map: sprite,
  alphaTest: 0.5,
  transparent: true,
})
import React from "react";
import { PortableText } from "@portabletext/react";

import "./TreeSection.scss";
// import { currentState } from './3d/models/States';

const TreeSection = (props) => {

  const { treeState } = props;

  const PortableTextComponents = {
    marks: {
      link: ({ value, children }) => {
        const target = (value?.href || "").startsWith("http")
          ? "_blank"
          : undefined;
        return (
          <a
            href={value?.href}
            target={target}
            rel={target === "_blank" && "noindex nofollow"}
          >
            {children}
          </a>
        );
      },
    },
  };
  return (
    <section className={`tree-section__container ${treeState !== 'intro' && treeState !== 'done' ? 'active' : ''}`} id="treeInfoWrapper">
      <div className="tree-section__wrapper">
        <h1 className={`tree-section__info ${treeState === 'carbon' ? 'active' : ''}`}>Carbon Sequestration</h1>
        <div className={`tree-section__info ${treeState === 'carbon' ? 'active' : ''}`}>
          <PortableText
            value={props.textOne}
            components={PortableTextComponents}
          />
        </div>
      </div>
      <div className="tree-section__wrapper">
        <h1 className={`tree-section__info ${treeState === 'water' ? 'active' : ''}`}>Water Cycles</h1>
        <div className={`tree-section__info ${treeState === 'water' ? 'active' : ''}`}>
          <PortableText
            value={props.textTwo}
            components={PortableTextComponents}
          />
        </div>
      </div>
      <div className="tree-section__wrapper">
        <h1 className={`tree-section__info ${treeState === 'bio' ? 'active' : ''}`}>Biodiversity</h1>
        <div className={`tree-section__info ${treeState === 'bio' ? 'active' : ''}`}>
          <PortableText
            value={props.textThree}
            components={PortableTextComponents}
          />
        </div>
      </div>
      <div className="tree-section__title-wrapper">
        <h4 className={`tree-section__title ${treeState === 'carbon' ? 'active' : ''}`}>CARBON SEQUESTRATION</h4>
        <h4 className={`tree-section__title ${treeState === 'water' ? 'active' : ''}`}>WATER CYCLES</h4>
        <h4 className={`tree-section__title ${treeState === 'bio' ? 'active' : ''}`}>BIODIVERSITY</h4>
      </div>
    </section>
  );
};

export default TreeSection;

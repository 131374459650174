import React from "react";
import Fade from "react-reveal/Fade";

import "./VideoBlock.scss";

const VideoBlock = (props) => {
  return (
    <Fade bottom distance={'100px'} delay={100}>
      <section className="video-block__wrapper">
        <video
          src={`${props.url}`}
          muted={true}
          playsInline={true}
          loop={true}
          autoPlay={true}
          className="video-block__video"
        ></video>
      </section>
    </Fade>
  );
};

export default VideoBlock;

import React, { useEffect, useState }  from "react";
import sanityClient from '../client.js';
import { PortableText } from "@portabletext/react";

import "./Footer.scss";

const Footer = () => {

  const [footerData, setFooterData] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "home"]{
        footerText,
        footerArray,
        }`
      )
      .then((data) => setFooterData(data))
      .catch(console.error);
  }, []);

  const PortableTextComponents = {
    marks: {
      link: ({ value, children }) => {
        const target = (value?.href || "").startsWith("http")
          ? "_blank"
          : undefined;
        return (
          <a
            href={value?.href}
            target={target}
            rel={target === "_blank" && "noindex nofollow"}
          >
            {children}
          </a>
        );
      },
    },
  };

  if(footerData){
  return (
    <section className="footer__container">
    <div className="footer__wrapper">
      <div className="footer__header-wrapper">
        <div className="footer__section-title">
          <h1>
            A visual
            <br />
            exploration
          </h1>
        </div>
        <div className="footer__text font-grey">
          <PortableText
            value={footerData[0].footerText}
            components={PortableTextComponents}
          />
        </div>
      </div>

      <h4 className="footer__further-reading font-grey">
        FURTHER READING
        <br />
        AND RESOURCES
      </h4>
      <div className="footer__list-wrapper">
        {footerData[0].footerArray.map((item, index) => {
          return (
            <a
              href={item.entryLink}
              target={"_blank"}
              rel={"noreferrer"}
              className="footer__list-item"
              key={index}
            >
              <p className="footer__credit-title">{item.entryName}</p>
              <p className="footer__credit-link">{item.entryLink}</p>
              <div className="footer__credit-arrow">{'\u2197'}</div>
            </a>
          );
        })}
      </div>
    </div>
    <div className="footer__spacer"></div>
    </section>
  );
}};

export default Footer;

import React from "react";
import { PortableText } from "@portabletext/react";
import Fade from "react-reveal/Fade";

import "./TitleParagraphBlock.scss";

const TitleParagraphBlock = (props) => {
  const PortableTextComponents = {
    marks: {
      link: ({ value, children }) => {
        const target = (value?.href || "").startsWith("http")
          ? "_blank"
          : undefined;
        return (
          <a
            href={value?.href}
            target={target}
            rel={target === "_blank" && "noindex nofollow"}
          >
            {children}
          </a>
        );
      },
    },
  };

  return (
    <section
      className={`split-text__wrapper ${props.margin ? "margin-bottom" : ""}`}
    >
      <hr className="split-text__rule" />

      <div className="split-text__text-wrapper">
        <Fade bottom distance={'100px'}>
          <div className="split-text__title-wrapper">
            <h1>{props.title}</h1>
            <h1 className="font-green">{props.subtitle}</h1>
          </div>
        </Fade>
        <Fade bottom delay={200} distance={'100px'}>
          <div className="split-text__content-wrapper font-grey">
            <PortableText
              value={props.text}
              components={PortableTextComponents}
            />
          </div>
        </Fade>
      </div>
    </section>
  );
};

export default TitleParagraphBlock;
